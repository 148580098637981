import React, {Component} from 'react'
import "./css/homepage.css"
export  default class Part2 extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <section className="section _logo-list -border">
                <div className="container"><a className="logo-link"><span
                    className="_text">Millions of users secured</span>
                    <div className="_logo logo-twitch">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 23.9">
                            <path
                                d="M68.3 3.7h-4.5V0H56l-3.7 3.7h-4.1l-2.1 2.1V3.7h-3.7V0h-14v3.7H11.5L7.8 0H0v16.5l3.7 3.7 6.2 3.7h5.3V23l2.1.8h10l1.8-2.1.2 2.1h6.5l.1-2.1 2.4 2.1H44l.4-2.1 2.1 2.1h6.6l2.1-2.1v2.1h3.7l2.9-2.1v2.1h4.1l6.2-4.1V7.4l-3.8-3.7zM9.9 9.9H6.2V14h3.7v4.5H4.5l-2.9-2.9v-14h4.5v3.7h3.7v4.6zm18.5 5.7l-2.9 2.9h-14V5.3H16V14h1.6V5.3h4.5V14h1.6V5.3h4.5v10.3zm6.2 2.9H30V5.3h4.5v13.2zm0-14.8H30V1.6h4.5v2.1zm9.8 6.2h-3.7V14h3.7v4.5h-5.3l-2.9-2.9v-14h4.5v3.7h3.7v4.6zm11.6 0h-5.3V14H56v4.5h-7l-2.9-2.9V8.2L49 5.3h7v4.6zm14.4 8.6h-4.5V9.9h-3.7v8.6h-4.5V1.6h4.5v3.7h5.3l2.9 2.9v10.3z"></path>
                        </svg>
                    </div>
                </a><a  className="logo-link"><span className="_text">Launched in 40 new countries</span>
                    <div className="_logo logo-zendesk">
                        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="170.6" height="40"
                             viewBox="0 0 170.6 40">
                            <style></style>
                            <title>logo-zendesk</title>
                            <path className="st0"
                                  d="M113.2 31.8c2.2 0 4.4-.8 6-2.3l3 3.2c-2 2-4.7 3.5-9 3.5-7.3 0-12-4.8-12-11.4 0-6.5 5-11.4 11.3-11.4 7.2 0 11.3 5.5 11 13.1h-17.1c.7 3.2 2.9 5.3 6.8 5.3m5.2-8.9c-.5-3-2.2-5.1-5.8-5.1-3.3 0-5.6 1.9-6.2 5.1h12zM.8 31.5L14 18.1H1.1v-4.2h19v4.3L6.9 31.6h13.4v4.2H.8zM35.1 31.8c2.2 0 4.4-.8 6-2.3l3 3.2c-2 2-4.7 3.5-9 3.5-7.3 0-12-4.8-12-11.4 0-6.5 5-11.4 11.3-11.4 7.2 0 11.3 5.5 11 13.1H28.3c.6 3.2 2.9 5.3 6.8 5.3m5.2-8.9c-.5-3-2.2-5.1-5.8-5.1-3.3 0-5.6 1.9-6.2 5.1h12zM73.9 24.8c0-7 5.2-11.4 11-11.4 2.9 0 5.7 1.3 7.3 3.3V3.8h4.7v31.9h-4.7v-3c-1.7 2.1-4.4 3.5-7.3 3.5-5.7 0-11-4.4-11-11.4m18.5 0c0-3.9-2.9-7.1-6.9-7.1-3.9 0-6.9 3.1-6.9 7.1s3 7.1 6.9 7.1c4 0 6.9-3.2 6.9-7.1M126.6 31.1l4.2-2.2c1.1 2 3.3 3.3 5.6 3.2 2.6 0 4-1.4 4-2.9 0-1.7-2.5-2.1-5.3-2.7-3.7-.8-7.6-2-7.6-6.5 0-3.5 3.3-6.6 8.5-6.6 4.1 0 7.1 1.6 8.8 4.2l-3.9 2.1c-1-1.5-2.7-2.5-4.9-2.5-2.5 0-3.8 1.2-3.8 2.6 0 1.6 2 2 5.2 2.7 3.6.8 7.7 2 7.7 6.5 0 3-2.6 7.1-8.9 7-4.6.2-7.8-1.7-9.6-4.9M157.7 26l-3.7 4.1v5.6h-4.6V3.8h4.6v20.9l9.9-10.8h5.7l-8.6 9.3 8.8 12.5h-5.3zM59.8 13.4c-5.5 0-10.2 3.6-10.2 9.5v12.8h4.7V23.6c0-3.6 2.1-5.7 5.6-5.7 3.5 0 5.3 2.1 5.3 5.7v12.2H70V23c0-6-4.6-9.6-10.2-9.6"></path>
                        </svg>
                    </div>
                </a><a className="logo-link"><span className="_text">Increased global sales</span>
                    <div className="_logo logo-nordstrom">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 13.7">
                            <path
                                d="M18.8 12.8c2.6 0 4.1-2.2 4.1-5.9S21.4 1 18.8 1s-4.1 2.2-4.1 5.7c0 3.9 1.5 6.1 4.1 6.1m0-12.8C22.6 0 25 2.7 25 6.7s-2.5 6.7-6.2 6.7-6.2-2.9-6.2-6.7S15.3 0 18.8 0m29.9 6.6c0-3.5-1.6-5.6-4.9-5.6-1.4 0-2.1.1-2.1.1s.1.3.1.9v4.8c0 1.7 0 3.6.1 4.8 0 .6-.1.9-.1.9h1.6c3.6-.1 5.3-2 5.3-5.9m1.9 0c0 4.2-2.4 6.7-6.4 6.7h-4.1s.1-4.1.1-6.6c0-2.4-.1-6.5-.1-6.5h4.8c3.7 0 5.7 2.2 5.7 6.4m40 6.2c2.6 0 4.1-2.2 4.1-5.9S93.2 1 90.6 1c-2.6 0-4.1 2.2-4.1 5.7 0 3.9 1.4 6.1 4.1 6.1m0-12.8c3.8 0 6.2 2.7 6.2 6.7s-2.5 6.7-6.2 6.7c-3.6 0-6.2-2.9-6.2-6.7S87 0 90.6 0M1.3 8.7c0 3.1.1 4.7.1 4.7H0S.1 8.2.1 3V.2h1s3.6 5.1 6.2 8.6c.2.5.5 1.1.8 1.5 0 0-.1-.9-.1-1.7V4.8C8 1.4 7.9.1 7.9.1h1.4s-.1 4.8-.1 10.4v2.7H8S4.4 8.1 2 4.6c-.4-.3-.6-.7-1-1.2 0 0 .1 1.1.1 1.7.2.1.2 3.6.2 3.6zm55.5 4.1c1.5 0 2.6-1.4 2.4-2.7-.1-1.1-1-1.7-2.5-2.6-1.8-1-2.9-1.7-3.1-3.5-.2-2.2 1.6-3.8 3.8-3.8 1.3 0 2.4.2 2.7.3v1.4s-1-1-2.7-1C56.3.9 55 1.7 55 3c0 1.1.9 1.7 2.5 2.6 2.2 1.3 3.1 2.3 3.1 4.1 0 2.4-1.8 4-4.1 4-1.3 0-2.4-.2-2.7-.3v-1.6c0-.2 1.1 1 3 1M71.3.2v1.3s-1-.3-2.5-.3h-.9V5c0 3.6.1 8.3.1 8.3h-2.1S66 8.5 66 5V1.1h-.9c-1.5 0-2.5.3-2.5.3V.2h8.7zM112 13.3h-2.1s-.1-4.8-.2-8.1V3.8s-.2.8-.5 1.4l-3.6 8.6s-2.1-4.7-3.6-8c-.2-.6-.5-1.5-.8-2.1v2.2c-.2 3.4-.2 7.5-.2 7.5h-1.3l.8-13.1h1.1s2.4 5.5 4 8.8c.2.3.3.9.5 1.4.1-.5.2-.9.5-1.4 1.4-3.4 3.6-8.8 3.6-8.8h1c.1-.1.8 13 .8 13zm-30.2-10c0 1.7-1.1 2.9-3 3.4 1.3 2.3 3.8 6.6 3.8 6.6h-2.1S79.4 11 76.6 6h.2c2.2 0 3.3-.9 3.3-2.6 0-1.5-.9-2.5-2.6-2.5h-1.3s.1.2.1 1.1v6.7c0 2.9.1 4.3.1 4.3h-2.1s.1-5.1.1-7.9c0-1.8-.1-5.2-.1-5.2h3.9c2.3.3 3.6 1.5 3.6 3.4m-45.6 0c0 1.7-1.1 2.9-3 3.4C34.4 9 37 13.3 37 13.3h-2.1S33.8 11 31 6h.2c2.2 0 3.3-.9 3.3-2.6 0-1.5-.9-2.5-2.6-2.5h-1.3s.1.2.1 1.1v6.7c0 2.9.1 4.3.1 4.3h-2.1s.1-5.1.1-7.9c.2-1.7.1-5.1.1-5.1h3.6c2.4.2 3.7 1.4 3.7 3.3"></path>
                        </svg>
                    </div>
                </a><a className="logo-link"><span className="_text">Worldwide support deployed in days</span>
                    <div className="_logo logo-lyft">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 28.4">
                            <path
                                d="M0 .6h6.1v16.3c0 2.6 1.2 4.1 2.1 4.8-1 .9-4 1.6-6.3-.2C.6 20.4 0 18.6 0 16.9V.6zm38 13.6v-1.7h1.9v-6h-2C37 2.2 32.8-.7 28.5.2c-3.8.8-6.5 4.1-6.4 8v14c1.4.2 2.9-.2 4-1.1 1.3-1.1 1.9-2.9 1.9-4.6V16h3v-6h-3V8.1c0-1.1.9-2 2-2s2 .9 2 2v6c0 4.4 3.6 8 8 8v-6c-1.1.1-2-.8-2-1.9zM15 6.4v8.8c-.1.5-.6.9-1.1.8-.4-.1-.7-.4-.8-.8V6.4h-6v10.4c0 1.9.6 4.2 3.5 5 2.9.8 4.5-.8 4.5-.8-.2 1.1-1.1 1.8-2.7 2-1.2.1-2.4-.2-3.5-.6v5.5c2 .6 4 .7 6.1.4 3.7-.7 6-3.8 6-7.9v-14h-6z"></path>
                        </svg>
                    </div>
                </a></div>
            </section>
        )
    }
}